import { useEffect, useState } from "react";
import {getAllDocs, getOrderDocByCondition} from "../repositories/firebase";
import { COLLECTIONS, User } from "../constants/types";
import { Order } from "../constants/collections";
import { convertTimestamp } from "../modules";
import firebase from "firebase/compat";
import {documentId, WhereFilterOp} from 'firebase/firestore';

export interface UsePatients {
  patients: User[];
  orders: Order[];
  loading: boolean;
}

export const usePatients = (): UsePatients => {
  const [patients, setPatients] = useState<User[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPatients = async () => {
    try {
      let stime = performance.now();
      let oid = new URLSearchParams(window.location.search).get("oid");
      if(!oid) return [];
      const conditions = [
        { field: 'id', operator: '==' as WhereFilterOp, value: oid },
      ];
      const _orders = await getOrderDocByCondition(conditions);
      const _patients = _orders.map((o:any) => {
        return o.patient_details;
      });
      console.log("Time taken to fetch patients: ", performance.now() - stime);
      setPatients(
        _patients.map((d) => ({
          ...d,
          dateOfBirth: convertTimestamp(d.dateOfBirth as any),
        }))
      );
      setOrders(
        _orders.map((o:any) => ({
          ...o,
          metadata: o?.metadata || {},
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPatients();
      setLoading(false);
    })();
  }, []);

  return {
    patients,
    loading,
    orders,
  };
};
