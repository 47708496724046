import { PageWrapper } from "../../layout";
import {
  AddPatient,
  Signature,
  AddressDetails,
  EndGreetings,
  OrderSummary,
  Questioner,
  Prescription,
} from "./sections";
import { useAppData } from "../../contexts/AppContext";
import {COLLECTIONS, CustomerData, Step} from "../../constants/types";
import Auth from "../Login/Auth";
import {Fragment, useEffect, useState} from "react";
import { addSubcollectionDoc, upsertDoc } from "../../repositories/firebase";
import { OrderStatus } from "../../constants/enums";
import { useSearchParams } from "react-router-dom";
import { User } from "../../constants/collections";
import BillingAddress from "./sections/BillingAddress";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
const sendConfirmationEmail = async (
  toEmail: string,
  toName: string,
  rxNumber: string
) => {
  try {
    const result = await emailjs.send(
      "service_7stbza7",
      "template_dxcgduq",
      {
        to_name: toName,
        rxNumber: rxNumber,
        to_email: toEmail,
      },
      "YFPpe2k8krkO9XWgm"
    );
  } catch (error) {}
};

function Home() {
  const {
    appFlow,
    authenticated,
    order,
    data,
    patientsData: { patients },
    disableContinue,
    user,
      triggerContinueAction,
    triggerContinue
  } = useAppData();
  const [submitting, setSubmitting] = useState(false);
  const [searchParams] = useSearchParams();

  const onSave = async (CustomStatus='None') => {
    try {
      if ((appFlow.currentStep === Step.Signature || CustomStatus == 'chargedSuccessfully' ) && order?.id) {
        setSubmitting(true);
        const response = await upsertDoc<any>(
          COLLECTIONS.ORDERS,
          {
            orderDate: new Date(),
            currentStatus: {
              status: OrderStatus.Ordered,
              date: new Date(),
            },
            // paymentMethod: { // will be handleded on backedn from now on
            //   cardHolderName:
            //     data.paymentInfo.firstName + " " + data.paymentInfo.lastName,
            //   cardNumber: data.paymentInfo.creditCardNumber || "",
            //   expirationMonth: data.paymentInfo.expiryMonth || "",
            //   expirationYear: data.paymentInfo.expiryYear || "",
            //   securityCode: data.paymentInfo.securityCode || "",
            //   zipCode: data.paymentInfo.zipCode || "",
            // },
            flavour: data.flavour,
            shippingOption: data.shippingOption,
            medications: data.medications,
            pregBF: data.pregBF || false,
            rushOrder: data.rushOrder || false,
            metadata: { ...order?.metadata },
            totalAmount: data.totalAmount,
            questions: data.questions,
            notes: data.notes,
            signatureWaived: data?.signatureWaived || false,
            pharmacyCall: data.pharmacyCall || false,
            statusHistory: [{
                status: OrderStatus.Ordered,
                date: new Date()
            }]
          },
          order?.id
        );
        let usr_id: string | undefined;
        if (order?.id) {
          const orderRef = doc(db, 'orders', order.id);
          const orderDoc = await getDoc(orderRef);
          if (orderDoc.exists()) {
              usr_id = orderDoc.data().userId;
          }
        }
        let user = patients.find((p) => p.id === usr_id);
        await upsertDoc<User>(
          COLLECTIONS.USERS,
          {
            email: data.email,
            internalNotes: data.internalNotes,
            address: {
              city: data.addresses.shippingAddress.city,
              postalCode: data.addresses.shippingAddress.zipCode || "",
              state: data.addresses.shippingAddress.state,
              street: data.addresses.shippingAddress.addressLine1,
              street2: data.addresses.shippingAddress.addressLine2,
            },
            sameAddress: data.addresses.bothSame,
            billingAddress: !data.addresses.bothSame
              ? {
                  city: data.addresses.billingAddress.city,
                  postalCode: data.addresses.billingAddress.zipCode || "",
                  state: data.addresses.billingAddress.state,
                  street: data.addresses.billingAddress.addressLine1,
                  street2: data.addresses.billingAddress.addressLine2,
                }
              : {},
          } as any,
          user?.id
        );
        if (data.email && CustomStatus == 'chargedSuccessfully') {
            if(process.env.REACT_APP_ENV !== 'dev') {
                sendConfirmationEmail(data.email, data.name, order.rxNumber);
            }
        }

        setSubmitting(false);
        appFlow.goToNextStep();
      } else {
        appFlow.goToNextStep();
      }
    } catch (e) {}
  };
    /*This gets trigger by OrderSummary*/
    useEffect(() => {
        //debugger;
        if(triggerContinue && triggerContinue?.do=="triggerPayment"){
            onChargePayment();
        }
    }, [triggerContinue]);
  // const onChargePayment = () => {
  //   alert("payment charged");
  // };
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_ENV === 'dev'
        ? 'http://127.0.0.1:5001/fir-34309/us-central1/app' : 'https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app');
  const onChargePayment = async () => {
    setSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/chargeCreditCard-New`, {
          data: {
              cardInfo: {
                  cardCode: data.paymentInfo.securityCode,
                  cardNumber: data.paymentInfo.creditCardNumber,
                  expiryDate:
                      data.paymentInfo.expiryMonth + "/" + data.paymentInfo.expiryYear,
              },
              address: {
                  firstName: data.paymentInfo.firstName,
                  lastName: data.paymentInfo.lastName,
                  company: "Art of Med",
                  address: data.addresses.shippingAddress.addressLine1,
                  city: data.addresses.shippingAddress.city,
                  state: data.addresses.shippingAddress.state,
                  zip: data.addresses.shippingAddress.zipCode,
                  country: data.addresses.shippingAddress.state,
              },
              amount: data?.totalAmount,
              uid: user?.id,
              oid: order?.id,
              description: "Auth.net Gateway",
              email: data.email,
              phoneNumber: user.phoneNumber,
              customerPaymentProfileId: data?.customerPaymentProfileId,
              mode: data?.mode,
              cvv: data?.cvv,
              cardLast4Digits: data?.cardLast4Digits
          },
      } as CustomerData);
      if (response.data.success) {
        await onSave('chargedSuccessfully');
        notifications.show({
          title: "Payment request submitted successfully!",
          message: response.data.message,
        });
        // add logs to the order collection | new one since we are not keeping single user and adding order against user ,
        // so we need to add logs to the order logically
        await addSubcollectionDoc(
          COLLECTIONS.ORDERS,
          order?.id,
          "order-logs",
          {
              title: `Payment charged.`,
              description: process.env.REACT_APP_ENV === 'dev' ? `Fake Payment has been charged of amount $${data?.totalAmount} by ${data?.name}.`
                  : `Payment has been charged of amount $${data?.totalAmount} by ${data?.name}.`,
          },
          {
              createdAt: true,
          }
        );
        appFlow.goToNextStep();
      } else {
        notifications.show({
          title: "Payment request failed",
          message: response.data.message,
          color: "red",
          autoClose: 10000,
          withCloseButton: true
        });
      }
    } catch (error: any) {
        if (error?.response?.data?.message?.includes('Payment already done')) {
            notifications.show({
                title: "Information",
                message: error?.response?.data?.message,
                color: "green",
                autoClose: 5000,
                withCloseButton: false
            });
            appFlow.goToNextStep();
            return;
        } else {
            notifications.show({
                title: "Payment request failed!",
                message: error?.response?.data?.message,
                color: "red",
                autoClose: 10000,
                withCloseButton: true
            });
        }
    } finally {
      triggerContinueAction({do: ''});
      setSubmitting(false);
    }
  };

  return (
    <PageWrapper
      submitting={submitting}
      onContinue={
        authenticated
          ? appFlow.currentStep === Step.OrderSummary
            ? onChargePayment
            : onSave
          : undefined
      }
      disableContinue={
        disableContinue[appFlow.currentStep] ||
        (appFlow.currentStep === Step.AddPatient && !data?.termsAgree)
      }
      onPrevious={
        appFlow.hasPrevStep() && appFlow.currentStep !== Step.EndGreetings
          ? () => {
              appFlow.goToPreviousStep();
            }
          : undefined
      }
      styles={{
        buttonsContainer:
          appFlow.currentStep === Step.EndGreetings
            ? {
                justifyContent: "center",
              }
            : undefined,
      }}>
      {authenticated ? (
        <Fragment>
          {appFlow.currentStep === Step.AddPatient && <AddPatient />}
          {appFlow.currentStep === Step.Prescription && <Prescription />}
          {appFlow.currentStep === Step.Questioner && <Questioner />}
          {appFlow.currentStep === Step.AddressDetails && <AddressDetails />}
          {appFlow.currentStep === Step.BillingAddress && <BillingAddress />}
          {appFlow.currentStep === Step.OrderSummary && <OrderSummary />}
          {appFlow.currentStep === Step.Signature && <Signature />}
          {appFlow.currentStep === Step.EndGreetings && <EndGreetings />}
        </Fragment>
      ) : (
        <Auth />
      )}
    </PageWrapper>
  );
}

export default Home;
